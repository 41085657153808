import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 495.000000 400.000000">
  <title>Logo</title>
  <g transform="translate(0.000000,400.000000) scale(0.100000,-0.100000)">
    <path d="M1100 2256 l0 -1346 275 0 275 0 0 -251 0 -251 413 5 c307 4 435 9
502 20 408 70 766 285 1018 612 148 192 262 460 303 714 19 117 21 367 4 471
-56 343 -195 627 -432 882 -179 191 -460 357 -725 428 -192 52 -194 52 -935
57 l-698 5 0 -1346z m1440 1100 c208 -44 312 -81 448 -159 283 -163 504 -419
608 -707 64 -179 85 -299 85 -485 0 -424 -177 -797 -506 -1065 -176 -143 -375
-234 -612 -280 -90 -17 -145 -20 -399 -20 l-294 0 0 135 0 135 347 0 c285 0
358 3 412 16 211 52 397 239 440 442 16 75 14 218 -4 285 -28 108 -115 245
-192 304 l-36 27 45 41 c59 52 110 118 141 180 53 108 72 267 47 392 -40 192
-204 370 -407 440 -41 15 -113 17 -530 20 l-483 4 0 -965 0 -966 -165 0 -165
0 0 1118 c0 615 3 1122 8 1126 4 5 261 6 572 3 464 -4 578 -8 640 -21z m92
-544 c84 -34 157 -105 198 -193 28 -61 33 -82 32 -148 0 -53 -6 -93 -22 -134
-29 -76 -113 -167 -188 -204 l-57 -28 -358 -5 c-197 -3 -361 -3 -363 -1 -2 2
-4 170 -4 373 l0 369 353 -3 c342 -3 354 -4 409 -26z m-25 -957 c87 -25 171
-99 217 -190 96 -188 5 -428 -192 -507 -55 -22 -69 -23 -409 -26 l-353 -3 0
369 c0 203 2 371 4 373 2 2 157 3 343 1 266 -2 349 -5 390 -17z" fill="currentColor"/>
  </g>
</svg>
);

export default IconLogo;
