import { Icon } from '@components/icons';
import { srConfig } from '@config';
import { usePrefersReducedMotion } from '@hooks';
import { KEY_CODES } from '@utils';
import sr from '@utils/sr';
import { graphql, useStaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { Keyboard, Mousewheel, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
// import "./styles.css";

const StyledJobsSection = styled.section`
  max-width: 700px;

  
  .inner {
    display: flex;

    @media (max-width: 600px) {
      display: block;
    }

    // Prevent container from jumping
    @media (min-width: 700px) {
      min-height: 340px;
    }

    
  }
  .swiper-button-prev {
    color: ${(props) => props.theme.global.logo};
  }

  .swiper-button-next {
    color: ${(props) => props.theme.global.logo};
  }

  .swiper-pagination-bullet {
    background-color: ${(props) => props.theme.global.color};
  }

  .swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
  .swiper-slide {
    align-items: center;
    background-position: center;
    background-size: cover;
    display: flex;
    width: 300px;
    height: 350px;
    text-align: center;
    justify-content: center;
  }
`;

const StyledTabList = styled.div`
  position: relative;
  z-index: 3;
  width: max-content;
  padding: 0;
  margin: 0;
  list-style: none;

  @media (max-width: 600px) {
    display: flex;
    overflow-x: auto;
    width: calc(100% + 100px);
    padding-left: 50px;
    margin-left: -50px;
    margin-bottom: 30px;
  }
  @media (max-width: 480px) {
    width: calc(100% + 50px);
    padding-left: 25px;
    margin-left: -25px;
  }

  li {
    &:first-of-type {
      @media (max-width: 600px) {
        margin-left: 50px;
      }
      @media (max-width: 480px) {
        margin-left: 25px;
      }
    }
    &:last-of-type {
      @media (max-width: 600px) {
        padding-right: 50px;
      }
      @media (max-width: 480px) {
        padding-right: 25px;
      }
    }
  }
`;

const StyledTabButton = styled.button`
  ${({ theme }) => theme.mixins.link};
  display: flex;
  align-items: center;
  width: 100%;
  height: var(--tab-height);
  padding: 0 20px 2px;
  border-left: 2px solid var(--lightest-navy);
  background-color: transparent;
  color: ${({ isActive }) => (isActive ? 'var(--trap)' : 'grey')};
  font-family: var(--font-mono);
  font-size: var(--fz-xs);
  text-align: left;
  white-space: nowrap;

  @media (max-width: 768px) {
    padding: 0 15px 2px;
  }
  @media (max-width: 600px) {
    ${({ theme }) => theme.mixins.flexCenter};
    min-width: 120px;
    padding: 0 15px;
    border-left: 0;
    border-bottom: 2px solid var(--lightest-navy);
    text-align: center;
  }

  &:hover,
  &:focus {
    // background-color: var(--light-navy);
    color: ${(props) => props.theme.global.logo};
  }
`;

const StyledHighlight = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 2px;
  height: var(--tab-height);
  border-radius: var(--border-radius);
  background: ${(props) => props.theme.global.logo};
  transform: translateY(calc(${({ activeTabId }) => activeTabId} * var(--tab-height)));
  transition: transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-delay: 0.1s;

  @media (max-width: 600px) {
    top: auto;
    bottom: 0;
    width: 100%;
    max-width: var(--tab-width);
    height: 2px;
    margin-left: 50px;
    transform: translateX(calc(${({ activeTabId }) => activeTabId} * var(--tab-width)));
  }
  @media (max-width: 480px) {
    margin-left: 25px;
  }
`;

const StyledTabPanels = styled.div`
  position: relative;
  width: 100%;
  margin-left: 20px;

  @media (max-width: 600px) {
    margin-left: 0;
  }
`;

const StyledTabPanel = styled.div`
  width: 100%;
  height: auto;
  padding: 10px 5px;

  ul {
    ${({ theme }) => theme.mixins.fancyList};
  }

  h3 {
    margin-bottom: 2px;
    font-size: var(--fz-xxl);
    font-weight: 500;
    line-height: 1.7;

    .company {
      color: ${(props) => props.theme.global.logo};
    }

    a {
      color: ${(props) => props.theme.global.logo};
    }
  }

  

  .range {
    margin-bottom: 25px;
    color: grey;
    font-family: var(--font-mono);
    font-size: var(--fz-xs);
  }
`;

const StyledPic = styled.div`
  position: relative;
  max-width: 300px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);

    .img {
      position: relative;
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      transition: var(--transition);
    }
  }

  a {
    ${({ theme }) => theme.mixins.flexCenter};
    padding: 10px;
    font-size: 14px;

    &.external {
      svg {
        width: 22px;
        height: 22px;
        // margin-top: 4px;
      }
    }
  }
`;

const Jobs = () => {

  const data = useStaticQuery(graphql`
    query {
      jobs: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/jobs/" } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              title
              company
              location
              range
              url
            }
            html
          }
        }
      }
    }
  `);

  const jobsData = data.jobs.edges;

  const [activeTabId, setActiveTabId] = useState(0);
  const [tabFocus, setTabFocus] = useState(null);
  const tabs = useRef([]);
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const focusTab = () => {
    if (tabs.current[tabFocus]) {
      tabs.current[tabFocus].focus();
      return;
    }
    // If we're at the end, go to the start
    if (tabFocus >= tabs.current.length) {
      setTabFocus(0);
    }
    // If we're at the start, move to the end
    if (tabFocus < 0) {
      setTabFocus(tabs.current.length - 1);
    }
  };

  // Only re-run the effect if tabFocus changes
  useEffect(() => focusTab(), [tabFocus]);

  // Focus on tabs when using up & down arrow keys
  const onKeyDown = e => {
    switch (e.key) {
      case KEY_CODES.ARROW_UP: {
        e.preventDefault();
        setTabFocus(tabFocus - 1);
        break;
      }

      case KEY_CODES.ARROW_DOWN: {
        e.preventDefault();
        setTabFocus(tabFocus + 1);
        break;
      }

      default: {
        break;
      }
    }
  };

  return (
    <StyledJobsSection id="jobs" ref={revealContainer}>
      <h2 className="numbered-heading">Experience</h2>

      <div className="inner">
        <StyledTabList role="tablist" aria-label="Job tabs" onKeyDown={e => onKeyDown(e)}>
          {jobsData &&
            jobsData.map(({ node }, i) => {
              const { company } = node.frontmatter;
              return (
                <StyledTabButton
                  key={i}
                  isActive={activeTabId === i}
                  onClick={() => setActiveTabId(i)}
                  ref={el => (tabs.current[i] = el)}
                  id={`tab-${i}`}
                  role="tab"
                  tabIndex={activeTabId === i ? '0' : '-1'}
                  aria-selected={activeTabId === i ? true : false}
                  aria-controls={`panel-${i}`}>
                  <span>{company}</span>
                </StyledTabButton>
              );
            })}
          <StyledHighlight activeTabId={activeTabId} />
        </StyledTabList>

        <StyledTabPanels>
          {jobsData &&
            jobsData.map(({ node }, i) => {
              const { frontmatter, html } = node;
              const { title, url, company, range } = frontmatter;

              return (
                <CSSTransition key={i} in={activeTabId === i} timeout={250} classNames="fade">
                  <StyledTabPanel
                    id={`panel-${i}`}
                    role="tabpanel"
                    tabIndex={activeTabId === i ? '0' : '-1'}
                    aria-labelledby={`tab-${i}`}
                    aria-hidden={activeTabId !== i}
                    hidden={activeTabId !== i}>
                    <h3>
                      <span>{title}</span>
                      <span className="company">
                        &nbsp;@&nbsp;
                        <a href={url} className="inline-link">
                          {company}
                        </a>
                      </span>
                    </h3>

                    <p className="range">{range}</p>

                    <div dangerouslySetInnerHTML={{ __html: html }} />
                  </StyledTabPanel>
                </CSSTransition>
              );
            })}
        </StyledTabPanels>
      </div>

      <h2 className="rowheading">CERTIFICATIONS</h2>
      <Swiper
        // pagination={{
        //   type: "progressbar",
        // }}
        cssMode={true}
        navigation={true}
        pagination={true}
        mousewheel={true}
        keyboard={true}
        modules={[Pagination, Navigation, Mousewheel, Keyboard]}
        className="mySwiper"
      >
        <SwiperSlide>
          <StyledPic>
            <div className="wrapper">
              <StaticImage
                className="img"
                src="https://media-exp1.licdn.com/dms/image/C4D0BAQHnDb1Rq_7PNQ/company-logo_100_100/0/1645010927195?e=2147483647&v=beta&t=6DtEdyIVoBsFuEbx-iQGyQhj_rvg8dXzMpIyNAiCdbg"
                width={500}
                quality={100}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Headshot"
              />
            </div>

            <a href="https://www.credly.com/badges/173c0639-de4d-4801-84d1-5a1881b28990/public_url"
              aria-label="External Link" className="external" target="_blank"
              rel="noreferrer"> IBM Full Stack Cloud Developer
              <Icon name="External" />
            </a>
          </StyledPic>
        </SwiperSlide>
        <SwiperSlide>
          <StyledPic>
            <div className="wrapper">
              <StaticImage
                className="img"
                src="https://media-exp1.licdn.com/dms/image/C4D0BAQHiNSL4Or29cg/company-logo_100_100/0/1519856215226?e=2147483647&v=beta&t=gBMr6FAdaUYVRZBRGsJwOgBtU7-kojeyhVAGmR6-Hd4"
                width={500}
                quality={100}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Headshot"
              />
            </div>

            <a href="https://www.credly.com/badges/46afb167-1701-429b-bf86-e0a192cd39b2/public_url"
              aria-label="External Link" className="external" target="_blank"
              rel="noreferrer"> Google IT Support
              <Icon name="External" />
            </a>
          </StyledPic>
        </SwiperSlide>
        <SwiperSlide>
          <StyledPic>
            <div className="wrapper">
              <StaticImage
                className="img"
                src="https://media-exp1.licdn.com/dms/image/C560BAQE_4UwvNNtNTQ/company-logo_100_100/0/1640205885683?e=2147483647&v=beta&t=Aqmvxbh9I3Uv6XnBvlWCAPw4AU3meh8EPznk1Nfv8NU"
                width={500}
                quality={100}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Headshot"
              />
            </div>

            <a href="https://www.coursera.org/account/accomplishments/specialization/TM6GBDQ6ZFHP"
              aria-label="External Link" className="external" target="_blank"
              rel="noreferrer"> DevOps on AWS
              <Icon name="External" />
            </a>
          </StyledPic>
        </SwiperSlide>

        {/* <SwiperSlide>
          <StyledPic>
            <div className="wrapper">
              <StaticImage
                className="img"
                src="https://media-exp1.licdn.com/dms/image/C4D0BAQGjwPKNaVZ7jQ/company-logo_100_100/0/1625578658814?e=1645660800&v=beta&t=utgU3Wp48IE7vm-7Y3-vaUh_5DuPGg3uRlbBAxfhhRs"
                width={500}
                quality={100}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Headshot"
              />
            </div>

            <a href="https://www.coursera.org/account/accomplishments/specialization/NT456BLKRVSW"
              aria-label="External Link" className="external" target="_blank"
              rel="noreferrer"> Cloud Application Development Foundations
              <Icon name="External" />
            </a>
          </StyledPic>
        </SwiperSlide>

        <SwiperSlide>
          <StyledPic>
            <div className="wrapper">
              <StaticImage
                className="img"
                src="https://media-exp1.licdn.com/dms/image/C4E0BAQGXW0yOhYBbCg/company-logo_100_100/0/1547069414882?e=1645660800&v=beta&t=2zVZfUEwSBdXnptR1NnQYhad7B3qSteRGMJs6pDUdiI"
                width={500}
                quality={100}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Headshot"
              />
            </div>

            <a href="https://www.coursera.org/account/accomplishments/verify/JSN2QVZQM8CF"
              aria-label="External Link" className="external" target="_blank"
              rel="noreferrer"> Managing Big Data with MySQL
              <Icon name="External" />
            </a>
          </StyledPic>
        </SwiperSlide> */}

        <SwiperSlide>
          <StyledPic>
            <div className="wrapper">
              <StaticImage
                className="img"
                src="https://media-exp1.licdn.com/dms/image/C4D0BAQHnDb1Rq_7PNQ/company-logo_100_100/0/1645010927195?e=2147483647&v=beta&t=6DtEdyIVoBsFuEbx-iQGyQhj_rvg8dXzMpIyNAiCdbg"
                width={500}
                quality={100}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Headshot"
              />
            </div>

            <a href="https://www.coursera.org/account/accomplishments/verify/FTVPD89Q5JXC"
              aria-label="External Link" className="external" target="_blank"
              rel="noreferrer"> Python for Data Science, AI & Development
              <Icon name="External" />
            </a>
          </StyledPic>
        </SwiperSlide>

        {/* <SwiperSlide>
          <StyledPic>
            <div className="wrapper">
              <StaticImage
                className="img"
                src="https://media-exp1.licdn.com/dms/image/C560BAQE_4UwvNNtNTQ/company-logo_100_100/0/1640205885683?e=2147483647&v=beta&t=Aqmvxbh9I3Uv6XnBvlWCAPw4AU3meh8EPznk1Nfv8NU"
                width={500}
                quality={100}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Headshot"
              />
            </div>

            <a href="https://www.coursera.org/account/accomplishments/verify/JZSXLMZRQ4NB"
              aria-label="External Link" className="external" target="_blank"
              rel="noreferrer"> Major Engineering Project Performance
              <Icon name="External" />
            </a>
          </StyledPic>
        </SwiperSlide>

        <SwiperSlide>
          <StyledPic>
            <div className="wrapper">
              <StaticImage
                className="img"
                src="https://media-exp1.licdn.com/dms/image/C4E0BAQFGfERBPGurCg/company-logo_100_100/0/1519856309184?e=1645660800&v=beta&t=ruaURv966Uh9wsWwmt6-BFZnpxmlZP7D93P7JGwRCGQ"
                width={500}
                quality={100}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Headshot"
              />
            </div>

            <a href="https://www.coursera.org/verify/7LDAB58EMQDV"
              aria-label="External Link" className="external" target="_blank"
              rel="noreferrer"> Python Data Structures
              <Icon name="External" />
            </a>
          </StyledPic>
        </SwiperSlide> */}

        <SwiperSlide>
          <StyledPic>
            <div className="wrapper">
              <StaticImage
                className="img"
                src="https://media-exp1.licdn.com/dms/image/C4D0BAQF0t_th0qelEQ/company-logo_100_100/0/1567194594099?e=2147483647&v=beta&t=J6wJPxPzGv-5lM5hk_-HzU75UyjNuWCkwy9pt3u4lrE"
                width={500}
                quality={100}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Headshot"
              />
            </div>

            <a href="https://www.coursera.org/verify/58PWG3B8YB8Q"
              aria-label="External Link" className="external" target="_blank"
              rel="noreferrer"> Six Sigma Tools for Improve and Control
              <Icon name="External" />
            </a>
          </StyledPic>
        </SwiperSlide>

      </Swiper>
    </StyledJobsSection>
  );
};

export default Jobs;
