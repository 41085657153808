import { srConfig } from "@config";
import { usePrefersReducedMotion } from "@hooks";
import sr from "@utils/sr";
import { StaticImage } from "gatsby-plugin-image";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";

const StyledAboutSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: 2fr 4fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }

  .btn {
    background-color: ${(props) => props.theme.global.logo};
    border: none;
    color: ${(props) => props.theme.global.color};
    padding: 8px 8px;
    font-size: 14px;
    font-family: var(--font-mono);
    cursor: pointer;
    margin-top: 10px;
    margin-right: 30px;
    border-radius: 2px;
  }

  /* Darker background on mouse-over */
  .btn:hover {
    background-color: ${(props) => props.theme.global.logo};
    font-size: 28px;
    font-weight: 700;
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        // content: '▹';
        content: "•";
        position: absolute;
        left: 0;
        color: ${(props) => props.theme.global.text};
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }

  p {
    font-size: 18px;
    line-height: 120%;
  }
`;

// const StyledText2 = styled.div`
// margin: 20px 0px 20px 0;
//   /* Create four equal columns that floats next to each other */
//   .column {
//     float: left;
//     width: 17.5%;
//     padding: 10px;
//   }

//   .column1 {
//     float: left;
//     width: 7%;
//     padding: 1px;
//   }

//   .row:after {
//     content: "";
//     display: table;
//     clear: both;
//   }

//   p {
//     position: relative;
//     margin-bottom: 10px;
//     padding-left: 20px;
//     font-family: var(--font-mono);
//     font-size: var(--fz-xs);

//     &:before {
//       // content: '▹';
//       content: '•';
//       position: absolute;
//       left: 0;
//       color: ${props => props.theme.global.text};
//       font-size: var(--fz-sm);
//       line-height: 12px;
//     }
//   }

//   @media screen and (max-width: 600px) {
//     .column {
//       width: 100%;
//     }
//   }

//   .collapsible {
//     border: 2px solid lightgrey;
//     background-color: rgb(255, 255, 255);
//     font-size: 16px;
//     height: 2.5em;
//     width: 2.5em;
//     border-radius: 999px;
//     position: relative;
//     cursor: pointer;

//     &:hover,
//     &:focus {
//       background: ${props => props.theme.global.logo};
//     }

//     &::after{
//       height: 0.2em;
//       width: 1em;
//       content: "";
//       display: block;
//       background-color: grey;
//       position: absolute;
//       top: 50%;
//       left: 50%;
//       transform: translate(-50%, -50%);
//     }

//     &::before{
//       height: 1em;
//       width: 0.2em;
//       content: "";
//       display: block;
//       background-color: grey;
//       position: absolute;
//       top: 50%;
//       left: 50%;
//       transform: translate(-50%, -50%);
//     }
//   }

//   .hidden {
//     visibility: hidden;
//   }

//   .none {
//     display: none;
//   }
// `;

const StyledPic = styled.div`
  grid-gap: 0 10px;
  position: relative;
  max-width: 300px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);

    .img {
      position: relative;
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      transition: var(--transition);
    }
  }
`;

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  // const [state1, setState1] = useState({
  //   classP1: 'none',
  //   classB1: 'column1',
  // });

  // const [state2, setState2] = useState({
  //   classP2: 'none',
  //   classB2: 'column1',
  // });

  // const [state3, setState3] = useState({
  //   classP3: 'none',
  //   classB3: 'column1',
  // });

  // const [state4, setState4] = useState({
  //   classP4: 'none',
  //   classB4: 'column1',
  // });

  // const toggleVisibility1 = () => {
  //   setState1({
  //     classP1: '',
  //     classB1: 'column1 hidden',
  //   });
  // };

  // const toggleVisibility2 = () => {
  //   setState2({
  //     classP2: '',
  //     classB2: 'column1 hidden',
  //   });
  // };

  // const toggleVisibility3 = () => {
  //   setState3({
  //     classP3: '',
  //     classB3: 'column1 hidden',
  //   });
  // };

  // const toggleVisibility4 = () => {
  //   setState4({
  //     classP4: '',
  //     classB4: 'column1 hidden',
  //   });
  // };

  // const languages = ['JavaScript (ES6+)', 'Python', 'SQL', 'GraphQL', 'Dart', 'Solidity'];
  // const frameworks = ['Node', 'React', 'Gatsby', 'Flask', 'Cypress', 'UiPath REF' ];
  // const tools = ['Git & Github', 'Android Studio', 'Flutter', 'Jira',
  //   'Selenium', 'Docker', 'Kubernetes'];
  // const mtools = ['Jmeter', 'Heroku', 'Firebase', 'Metabase', 'New Relic', 'Azure DevOps', 'Chrome DevTools' ];

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading">About Me</h2>

      <div className="inner">
        <StyledPic>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/me.webp"
              width={500}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Headshot"
            />
          </div>
        </StyledPic>

        <StyledText>
          <div>
            <p>
              Hello! My name is Ayo and I enjoy leveraging emerging technologies
              to solve problems.
            </p>

            <p>
              I enjoy bridging the gap between technology and business needs —
              combining my technical knowledge with my keen eye for design to
              create a beautiful product. My goal is to always build
              applications that are scalable and efficient under the hood while
              providing engaging, pixel-perfect user experiences.
            </p>

            <p>
              I am proficient with popular frameworks in mainstream programming
              languages. I also have extensive professional experience in web /
              mobile development. I am stack-agnostic and embrace any
              opportunity to learn new frameworks.
            </p>

            {/* <p>
              I am proficient with popular frameworks in mainstream programming languages.
              The use cases for these languages are not limited to web development.
              As at such, I also have professional experience in data analysis and software testing.
              I also embrace every opportunity to learn new frameworks.
            </p> */}

            {/* <p>
              Fast-forward to today, and I've had the privilege of working at{' '}
              <a href="https://us.mullenlowe.com/">an advertising agency</a>,{' '}
              <a href="https://starry.com/">a start-up</a>,{' '}
              <a href="https://www.apple.com/">a huge corporation</a>, and{' '}
              <a href="https://scout.camd.northeastern.edu/">a student-led design studio</a>. My
              main focus these days is building accessible, inclusive products and digital
              experiences at <a href="https://upstatement.com/">Upstatement</a> for a variety of
              clients.
            </p> */}

            {/* <p>
              I also recently{' '}
              <a href="https://www.newline.co/courses/build-a-spotify-connected-app">
                launched a course
              </a>{' '}
              that covers everything you need to build a web app with the Spotify API using Node
              &amp; React.
            </p> */}

            <p>I currently work with : </p>

            <button className="btn"> Dart: Flutter</button>
            <button className="btn"> JavaScript: Node/React/Gatsby</button>
            <button className="btn"> Python: Flask</button>
            <button className="btn">
              {" "}
              DB: SQL/GraphQL/FireBase/DynamoDB/SQlite
            </button>
          </div>

          {/* <ul className="skills-list">
            {skills && skills.map((skill, i) => <li key={i}>{skill}</li>)}
          </ul> */}
        </StyledText>
      </div>
    </StyledAboutSection>
  );
};

export default About;

// <StyledText2>
//  <div className="row">
//    <div className="column">
//      <h2 className="rowheading">LANGUAGES</h2>
//      {languages && languages.map((language, i) => <p className={state1.classP1} key={i}>{language}</p>)}
//    </div>
//    {/* <div class="column1"><button class="collapsible"></button></div> */}
//    <div className={state1.classB1}><button onClick={toggleVisibility1} className="collapsible"></button></div>
//    <div className="column" >
//      <h2 className="rowheading">FRAMEWORKS</h2>
//      {frameworks && frameworks.map((framework, i) => <p className={state2.classP2} key={i}>{framework}</p>)}
//    </div>
//    <div className={state2.classB2}><button onClick={toggleVisibility2} className="collapsible"></button></div>
//    <div className="column" >
//      <h2 className="rowheading">TOOLS</h2>
//      {tools && tools.map((tool, i) => <p className={state3.classP3} key={i}>{tool}</p>)}
//    </div>
//    <div className={state3.classB3}><button onClick={toggleVisibility3} className="collapsible"></button></div>
//    <div className="column" >
//      <h2 className="rowheading">AND MORE</h2>
//      {mtools && mtools.map((mtool, i) => <p className={state4.classP4} key={i}>{mtool}</p>)}
//    </div>
//    <div className={state4.classB4}><button onClick={toggleVisibility4} className="collapsible"></button></div>
//  </div>
// </StyledText2>;
