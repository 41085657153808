import { usePrefersReducedMotion } from '@hooks';
import { loaderDelay, navDelay } from '@utils';
import React, { useEffect, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
// import { email } from '@config';

const StyledHeroSection = styled.section`
  ${({ theme }) => theme.mixins.flexCenter};
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  padding: 100px 0px 25px;

  @media (max-width: 480px) and (min-height: 700px) {
    padding-bottom: 10vh;
  }

  .wave-emoji {
    animation-duration: 1.8s;
    animation-iteration-count: infinite;
    animation-name: wave;
    display: inline-block;
    transform-origin: 70% 70%;
  }

  @keyframes wave {
    0% {
      transform: rotate(0deg);
    }
    10% {
      transform: rotate(-10deg);
    }
    20% {
      transform: rotate(12deg);
    }
    30% {
      transform: rotate(-10deg);
    }
    40% {
      transform: rotate(9deg);
    }
    50% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  h1 {
    margin: 30px 0 10px 4px;
    color: ${props => props.theme.global.text};
    font-family: var(--font-mono);
    font-size: clamp(var(--fz-sm), 5vw, var(--fz-md));
    font-weight: 500;

    @media (max-width: 480px) {
      margin: 30px 0 20px 2px;
    }
  }

  h3 {
    margin-top: 10px;
    color: ${props => props.theme.global.text};
    line-height: 0.9;
  }

  p {
    font-family: var(--font-mono);
    margin: 20px 0 0;
    max-width: 700px;
    font-weight: 500;
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 30px;
    color: ${props => props.theme.global.color};
    font-size: var(--fz-sm);
    font-family: var(--font-mono);
    font-weight: 600;
  }
`;

const Hero = () => {
  const [isMounted, setIsMounted] = useState(false);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    const timeout = setTimeout(() => setIsMounted(true), navDelay);
    return () => clearTimeout(timeout);
  }, []);

  const one = (
    <h1> Hi,
      <span className="wave-emoji">
        <img alt="👋" draggable="false" src="https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f44b.png"
          style={{ height: '1.5em', width: '1.5em', margin: '0px 0.05em 0px 0.1em' }} >
        </img>
      </span> my name is
    </h1>
  );

  const two = <h2 className="big-heading" > Ayodeji Olabisi. </h2>;
  const three = <h3 className="big-heading" > I build high quality applications. </h3>;

  const four = (
    <>
      <br></br>
      <p>
        I'm a software developer specializing in building excellent
        digital experiences. I'm actively commited to building accessible, human-centered digital products
        for use. {' '}
      </p>

    </>
  );

  const five = (
    <a className="email-link"
      href="mailto:mr.ayodejiolabisi@gmail.com"
      target="_blank"
      rel="noreferrer" >
      Reach Out!
    </a>
  );

  const items = [one, two, three, four, five];

  return (
    <StyledHeroSection>
      {prefersReducedMotion ? (
        <>
          {items.map((item, i) => (
            <div key={i}>{item}</div>
          ))}
        </>
      ) : (
        <TransitionGroup component={null}>
          {isMounted &&
            items.map((item, i) => (
              <CSSTransition key={i} classNames="fadeup" timeout={loaderDelay}>
                <div style={{ transitionDelay: `${i + 1}00ms` }}>{item}</div>
              </CSSTransition>
            ))}
        </TransitionGroup>
      )}
    </StyledHeroSection>
  );

};

export default Hero;