import { IconLogo } from '@components/icons';
import { navLinks } from '@config';
import { usePrefersReducedMotion, useScrollDirection } from '@hooks';
import { loaderDelay } from '@utils';
import { Link } from 'gatsby';
import { useStyledDarkMode } from 'gatsby-styled-components-dark-mode';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled, { css } from 'styled-components';

const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  z-index: 11;
  padding: 5px 50px;
  width: 100%;
  height: 100px;
  filter: none !important;
  pointer-events: auto !important;
  user-select: auto !important;
  background-color: ${props => props.theme.global.bg};
  backdrop-filter: blur(10px);
  transition: var(--transition);
  // border: 2px solid grey;
  // border-radius: 10px;

  hr {
    width: 50%;
    height: 2px;
    background-color: grey;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: auto;
    margin-left: auto;
    border-width: 2px;
  }

  @media (max-width: 1080px) {
    padding: 10px 40px;
  }
  @media (max-width: 768px) {
    padding: 10px 25px;
  }

  @media (prefers-reduced-motion: no-preference) {
    ${props =>
    props.scrollDirection === 'up' &&
      !props.scrolledToTop &&
      css`
        height: 100px;
      `};

    ${props =>
    props.scrollDirection === 'down' &&
      !props.scrolledToTop &&
      css`
        height: 50px;
      `};
  }
`;

const StyledNav = styled.nav`
  ${({ theme }) => theme.mixins.flexBetween};
  position: relative;
  width: 100%;
  font-family: var(--font-mono);
  counter-reset: item 0;
  z-index: 12;

  input {
    ${({ theme }) => theme.mixins.flexCenter};
    -webkit-appearance: none;
    position: relative;
    width: 80px;
    height: 40px;
    background-image: url(https://storage.googleapis.com/bisiweb.appspot.com/light.png);
    background-size: cover;
    border-radius: 50px;
    outline: none;
    transition: background-image 0.9s;
    box-shadow: 0px 2px 5px 1px gray;
  }

  input:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 40px;
    width: 40px;
    background-color: navy;
    border-radius: 50px;
    transition: all 0.9s;
    background-color: #f7ca33;
  }

  input:checked {
    background-image: url(https://storage.googleapis.com/bisiweb.appspot.com/dark.png);
    transition: background-image 0.9s;
  }

  input:checked:before {
    transform: translate(100%);
    transition: all 0.9s;
    background-color: #ecf0f3;
  }

  .logo {
    ${({ theme }) => theme.mixins.flexCenter};

    a {
      color: var(--green);
      width: 42px;
      height: 42px;

      &:hover,
      &:focus {
        svg {
          fill: var(--green-tint);
        }
      }

      svg {
        fill: none;
        transition: var(--transition);
        user-select: none;
      }
    }
  }

  @media (prefers-reduced-motion: no-preference) {
    ${props =>
    props.scrollDirection === 'up' && !props.scrolledToTop && css``};

    ${props =>
    props.scrollDirection === 'down' &&
      !props.scrolledToTop &&
      css`
        display: none;
      `};
  }
`;

const StyledNav2 = styled.nav`
  ${({ theme }) => theme.mixins.flexCenter};
  position: relative;
  width: 100%;
  font-family: Oxygen Mono;
  counter-reset: item 0;
  z-index: 12;
  overflow: auto;
  white-space: nowrap;
`;

const StyledLinks = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: true;
  }

  ul {
    ${({ theme }) => theme.mixins.flexCenter};
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      margin: 0 5px;
      position: relative;
      counter-increment: item 1;
      font-size: var(--fz-xs);

      a {
        padding: 10px;
        font-weight: 500;

        &:before {
          margin-right: 5px;
          color: var(--green);
          font-size: var(--fz-xxs);
          text-align: right;
        }
      }
    }
  }

  p {
    margin: 0 5px;
    position: relative;
    counter-increment: item 1;
    font-size: var(--fz-xs);

    a {
      padding: 10px;
      font-weight: 500;

      &:before {
        margin-right: 5px;
        color: var(--green);
        font-size: var(--fz-xxs);
        text-align: right;
      }
    }
  }
`;

const Nav = ({ isHome }) => {
  const [isMounted, setIsMounted] = useState(!isHome);
  const scrollDirection = useScrollDirection('down');
  const [scrolledToTop, setScrolledToTop] = useState(true);
  const prefersReducedMotion = usePrefersReducedMotion();
  const { isDark, toggleDark } = useStyledDarkMode();

  const handleScroll = () => {
    setScrolledToTop(window.pageYOffset < 50);
  };

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    const timeout = setTimeout(() => {
      setIsMounted(true);
    }, 100);

    window.addEventListener('scroll', handleScroll);

    return () => {
      clearTimeout(timeout);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const timeout = isHome ? loaderDelay : 0;
  const fadeClass = isHome ? 'fade' : '';
  const fadeDownClass = isHome ? 'fadedown' : '';

  const Logo = (
    <div className="logo" tabIndex="-1">
      {isHome ? (
        <a href="/" aria-label="home">
          <IconLogo />
        </a>
      ) : (
        <Link to="/" aria-label="home">
          <IconLogo />
        </Link>
      )}
    </div>
  );

  const CheckMode = (
    <>
      <input type="checkbox" onChange={() => toggleDark()} checked={!!isDark} />
    </>
  );

  return (
    <StyledHeader
      scrollDirection={scrollDirection}
      scrolledToTop={scrolledToTop}
    >
      <StyledNav
        scrollDirection={scrollDirection}
        scrolledToTop={scrolledToTop}
      >
        {prefersReducedMotion ? (
          <>
            {Logo}
            {CheckMode}
          </>
        ) : (
          <>
            <TransitionGroup component={null}>
              {isMounted && (
                <CSSTransition classNames={fadeClass} timeout={timeout}>
                  <>{Logo}</>
                </CSSTransition>
              )}
            </TransitionGroup>

            <TransitionGroup component={null}>
              {isMounted && (
                <CSSTransition classNames={fadeDownClass} timeout={timeout}>
                  <div
                    style={{
                      transitionDelay: `${
                        isHome ? navLinks.length * 100 : 0
                      }ms`,
                    }}
                  >
                    {CheckMode}
                  </div>
                </CSSTransition>
              )}
            </TransitionGroup>
          </>
        )}
      </StyledNav>

      <StyledNav2>
        {prefersReducedMotion ? (
          <>
            <StyledLinks>
              <ul>
                {navLinks &&
                  navLinks.map(({ url, name }, i) => (
                    <li key={i}>
                      <Link to={url}>{name}</Link>
                    </li>
                  ))}
              </ul>
            </StyledLinks>
          </>
        ) : (
          <>
            <StyledLinks>
              <ul>
                <TransitionGroup component={null}>
                  {isMounted &&
                    navLinks &&
                    navLinks.map(({ url, name }, i) => (
                      <CSSTransition
                        key={i}
                        classNames={fadeDownClass}
                        timeout={timeout}
                      >
                        <li
                          key={i}
                          style={{
                            transitionDelay: `${isHome ? i * 100 : 0}ms`,
                          }}
                        >
                          <Link to={url}>{name}</Link>
                        </li>
                      </CSSTransition>
                    ))}
                </TransitionGroup>
              </ul>

              <TransitionGroup component={null}>
                {isMounted && (
                  <CSSTransition classNames={fadeDownClass} timeout={timeout}>
                    <div
                      style={{
                        transitionDelay: `${
                          isHome ? navLinks.length * 100 : 0
                        }ms`,
                      }}
                    ></div>
                  </CSSTransition>
                )}
              </TransitionGroup>
            </StyledLinks>
          </>
        )}
      </StyledNav2>
      <hr></hr>
    </StyledHeader>
  );
};

Nav.propTypes = {
  isHome: PropTypes.bool,
};

export default Nav;
