import React from 'react';

const IconBlog = () => (

  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" role="img" viewBox="0 0 512 512" xmlXspace="preserve" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><title>Blog</title><g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"><path d="M525 5028 c-27 -5 -86 -27 -131 -48 -152 -73 -274 -232 -303 -394 -8
-41 -11 -695 -11 -2098 l0 -2039 25 -24 c24 -25 24 -25 240 -25 l215 0 0 -135
c0 -131 1 -137 25 -160 l24 -25 1631 0 1631 0 24 25 25 24 0 998 0 998 430
430 430 430 -21 -75 c-25 -92 -92 -236 -149 -321 -24 -35 -147 -169 -274 -297
l-230 -232 57 -58 58 -57 232 235 c293 296 371 408 445 640 27 85 62 270 62
329 0 12 11 39 24 60 113 179 42 418 -153 514 -59 29 -74 32 -156 32 -147 0
-161 -9 -483 -329 l-272 -270 0 385 0 386 55 12 c129 29 276 136 342 248 133
227 100 503 -82 686 -76 77 -190 138 -289 156 -77 14 -3348 13 -3421 -1z m279
-186 c88 -41 148 -100 191 -185 19 -38 35 -76 35 -83 0 -11 -27 -14 -131 -14
-74 0 -150 -5 -173 -12 -55 -16 -138 -99 -154 -154 -9 -32 -12 -478 -12 -1938
l0 -1896 -160 0 -160 0 2 2008 3 2007 32 67 c40 85 97 147 172 186 78 42 122
52 213 48 61 -3 92 -11 142 -34z m3208 -4 c91 -45 147 -103 191 -196 29 -61
32 -76 32 -162 0 -86 -3 -101 -32 -162 -44 -93 -100 -151 -191 -196 l-76 -37
-1449 -3 -1450 -2 17 22 c113 154 141 229 141 378 0 76 -5 120 -19 160 -21 64
-78 171 -101 190 -9 7 -21 22 -26 32 -10 18 32 18 1439 16 l1448 -3 76 -37z
m-2982 -450 c0 -29 -62 -140 -101 -182 -39 -43 -163 -116 -195 -116 -11 0 -14
27 -14 130 0 126 1 132 25 155 23 24 29 25 155 25 97 0 130 -3 130 -12z m2730
-931 l0 -462 -178 -178 -177 -177 -1183 0 -1182 0 0 -80 0 -80 1102 0 1103 0
-80 -80 -80 -80 -1022 0 -1023 0 0 -80 0 -80 942 0 943 0 -257 -257 -256 -258
-126 -252 c-69 -139 -126 -264 -126 -277 0 -13 10 -36 21 -51 l22 -27 -89 -89
-89 -89 58 -57 57 -58 89 89 89 89 27 -22 c15 -11 38 -21 51 -21 13 0 138 57
277 126 l252 126 418 416 417 417 0 -863 0 -862 -1520 0 -1520 0 0 1840 0
1840 1520 0 1520 0 0 -463z m1011 115 c43 -22 59 -38 81 -81 57 -108 34 -172
-111 -316 l-101 -100 -142 143 -143 142 100 101 c144 145 208 168 316 111z
m-486 -372 l40 -40 -503 -503 -502 -502 -42 43 -43 42 500 500 c275 275 502
500 505 500 3 0 24 -18 45 -40z m200 -200 l40 -40 -503 -503 -502 -502 -42 43
-43 42 500 500 c275 275 502 500 505 500 3 0 24 -18 45 -40z m-1220 -1020
l140 -140 -263 -263 -262 -262 -142 143 -143 142 260 260 c143 143 262 260
265 260 3 0 68 -63 145 -140z m-641 -639 l119 -119 -163 -82 -163 -82 -39 40
-40 39 81 161 c45 89 82 162 84 162 1 0 56 -54 121 -119z"></path><path d="M1040 3520 l0 -80 1200 0 1200 0 0 80 0 80 -1200 0 -1200 0 0 -80z"></path><path d="M1040 3200 l0 -80 1200 0 1200 0 0 80 0 80 -1200 0 -1200 0 0 -80z"></path><path d="M1040 2880 l0 -80 1200 0 1200 0 0 80 0 80 -1200 0 -1200 0 0 -80z"></path><path d="M1040 960 l0 -80 440 0 440 0 0 80 0 80 -440 0 -440 0 0 -80z"></path><path d="M1040 640 l0 -80 1200 0 1200 0 0 80 0 80 -1200 0 -1200 0 0 -80z"></path></g></svg>

);

export default IconBlog;
