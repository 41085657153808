import { email, srConfig } from "@config";
import { usePrefersReducedMotion } from "@hooks";
import sr from "@utils/sr";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";

const StyledContactSection = styled.section`
  max-width: 600px;
  padding: 30px 0px 0px;
  text-align: center;

  @media (max-width: 768px) {
    margin: 0 auto 50px;
  }

  .overline {
    display: block;
    margin-bottom: 20px;
    color: ${(props) => props.theme.global.text};
    font-family: var(--font-mono);
    font-size: var(--fz-md);
    font-weight: 400;

    &:before {
      bottom: 0;
      font-size: var(--fz-sm);
    }

    &:after {
      display: none;
    }
  }

  .title {
    font-size: clamp(40px, 5vw, 60px);
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 10px;
    margin-bottom: 10px;
    color: ${(props) => props.theme.global.color};
    font-size: var(--fz-sm);
    font-family: var(--font-mono);
    font-weight: 600;
  }
`;

const Contact = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  return (
    <StyledContactSection id="contact" ref={revealContainer}>
      {/* <h2 className="numbered-heading overline">What’s Next?</h2> */}

      <h2 className="title">Get In Touch</h2>

      <p>by sending a mail to my inbox</p>

      <a className="email-link" href={`mailto:${email}`}>
        Reach Out!
      </a>

      <p>or on Social Media</p>
    </StyledContactSection>
  );
};

export default Contact;
